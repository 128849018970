nav {
    background-color: rgb(54, 49, 47);
}

nav ul {
    list-style-type: none;
    width: 50vw;
    margin: auto;
    padding: 0;
    overflow: hidden;
}

nav li {
    float: left;
    display: block;
    font-size: larger;
    padding: 0.5vw 1.5vw 0.5vw 1.5vw;
}

.navActive {
    list-style-position: inside;
    border-bottom: 4px solid rgb(210, 0, 190);
}

nav .left {
    float: left;
}

nav .right {
    float: right;
}
