#profileMini {
    height: 8vw;
}

#profileMini #pfp {
    width: 8vw;
    height: 8vw;
}

#profileMini #content {
    display: inline-block;
    vertical-align: middle;
    transform: translateY(-4vw);
    padding-left: 1vw;
}
