html {
    background-color: rgb(37, 34, 32);
    font-family: sans-serif;
    color: white;
}

a {
    text-decoration: none;
    color: white;
}

.tile {
    background-color: rgb(54, 49, 47);
    width: 50vw;
    margin: auto;
    margin-top: 1vw;
}

.padded {
    width: 48vw;
    padding: 1vw;
}

.active {
    background-color: rgb(210, 0, 190);
}

.faded {
    color: gray;
}

button, input, textarea {
    text-decoration: none;
    border: none;
    padding: 0.4vw;
    margin: 0px 0.4vw 0.4vw 0px;
    font-family: sans-serif;
    font-size: 16px;
    color: white;
    background-color: rgb(75, 68, 64);
}
