#comment {
    margin-top: 2vw;
}

#comment #header {
    display: inline;
}

#comment #controls {
    display: inline;
    float: right;
}

#comment #text {
    display: inline;
    white-space: pre-wrap;
}

#comment .replyButton {
    background: none;
    padding: 0;
    font-size: 12px;
    color: gray;
}
