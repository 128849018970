#reply {
    padding-left: 2vw;
    margin-top: 1vw;
}

#reply #header {
    display: inline;
}

#reply #controls {
    display: inline;
    float: right;
}

#reply #text {
    display: inline;
    white-space: pre-wrap;
}
